<template>
  <div class="resourceInitialize">
    <div class="top">
      <div class="title">
        <div class="title-text">资源初始化</div>
        <div class="title-description">
          初始化设置的资源数量有效期为长期有效，用完即止。
        </div>
      </div>
    </div>
    <a-divider></a-divider>

    <!-- 初始化表单 -->
    <div class="initialForm">
      <a-form :form="form" style="width: 50%">
        <a-form-item label="资源初始化sku code">
          <a-input
            :maxLength="30"
            :disabled="!isModify"
            v-decorator="[
              'skucode',
              {
                initialValue: skucode || '',
                rules: [
                  { required: true, message: '请输入资源初始化sku code' },
                ],
              },
            ]"
          ></a-input>
          <span style="position: absolute; bottom: -11px; right: 10px"
            >{{ getItemValueLength('skucode') }}/30</span
          >
        </a-form-item>
        <a-form-item>
          <!-- 按钮 -->
          <div style="float: right">
            <div v-if="!isModify">
              <a-button @click="isModify = true" type="primary">编辑</a-button>
            </div>
            <div v-else>
              <a-button @click="cancel">取消</a-button
              ><a-button type="primary" @click="modifyResource">保存</a-button>
            </div>
          </div>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResourceInitialize',
  inject: ['reload'],
  data() {
    return {
      form: this.$form.createForm(this),
      isModify: false,
      skucode: '',
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getResourceData()
    },
    /* 获取输入框值长度 */
    getItemValueLength(val) {
      const value = this.form.getFieldsValue([val])[val]
      return value ? value.length : '0'
    },
    /* 获取资源初始化skucode数据 */
    getResourceData() {
      this.$api
        .getResourceInitial()
        .then((res) => {
          if (res.code == 0) {
            // console.log(res.data, '资源初始化值')
            this.skucode = res.data
          } else if (res.code === 1001) {
            this.$message.warning('未设置skucode!')
          } else {
            console.log(err, '获取资源配置值失败')
          }
        })
        .catch((err) => {
          console.log(err, '获取资源配置值失败')
        })
    },

    /* 修改初始化资源数据 */
    modifyResource() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values, '修改数据')

          /* 发送修改初始化配置请求 */
          this.$api._modifyResourceInitial(values.skucode).then((res) => {
            // console.log(res)
            if (res.code === 0) {
              this.$message.success('保存成功')
              this.init()
              this.isModify = false
            }
          })
        }
      })
    },
    /* 取消修改 */
    cancel() {
      this.form.setFieldsValue({ skucode: this.skucode })
      this.isModify = false
    },
  },
}
</script>

<style lang="less" scoped>
@import '@/assets/CSS/global.less';

.resourceInitialize {
  background-color: #fff;
  // height: calc(100vh - 120px);
  margin-top: 5px;
  padding: 15px;
  border-radius: 3px;
  overflow: auto;
}

.top {
  .title {
    // height: 100px;
    margin-bottom: 15px;
    .title-text {
      margin-left: 10px;
      margin-bottom: 15px;
      position: relative;
      font-family: '.PingFang SC S0pxibold';
      font-weight: 600;
      font-size: 16px;
      text-align: left;
      color: #151515;
      /* 伪类 */
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -10px;
        transform: translateY(-50%);
        width: 4px;
        height: 20px;
        border-radius: 3px;
        background: #1890ff;
      }
    }
    .title-description {
      font-weight: 400;
      font-size: 14px;
      text-align: left;
      color: #999;
    }
  }
}

/deep/ .ant-form-item {
  margin-bottom: 10px;
}

.ant-btn {
  width: 100px;
  margin-left: 10px;
}

.bottom-btn {
  position: absolute;
  bottom: 0;
  right: 0;
}

.initialForm {
  display: flex;
}

.number {
  font-family: '苹方-简 常规体';
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  text-align: left;
  color: #333;
}
</style>
